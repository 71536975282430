import Keycloak from "keycloak-js";

import { talosConfig } from "../utilities";
import { IAuthContext } from "./context";

export const initializeAuthContext = async (): Promise<IAuthContext> => {
	const talosKeycloak = new Keycloak(talosConfig.keycloak.config);

	const authenticated = await talosKeycloak.init(
		talosConfig.keycloak.initOptions
	);

	if (authenticated) {
		const profile = await talosKeycloak.loadUserProfile();

		return {
			userName: `${profile.firstName} ${profile.lastName}`,
			email: `${profile.email}`,
			provider: talosKeycloak,
			hasRole: (roleName: string) => talosKeycloak.hasRealmRole(roleName),
			hasAnyRole: () => talosKeycloak.realmAccess != undefined,
		};
	}

	throw new Error("Keycloak failed to authenticate");
};
