import React, { useContext, useState } from "react";

import { useMutation, useQuery, useQueryClient } from "react-query";
import {
	Box,
	CircularProgress,
	Divider,
	Link,
	List,
	ListItem,
	ListItemText,
	Typography,
	useTheme,
} from "@mui/material";
import { subDays } from "date-fns";

import { AuthContext } from "../../auth";
import { BookingRequestsTable, TalosDateRangePicker } from "../../components";
import { DateRange } from "../../models/talos";
import { AlertContext, handleError, ROLES } from "../../utilities";
import { cancelBookingRequests, getBookingRequests } from "../../api/talos";
import { ListPagination } from "../../components/pagination/pagination";

const THREE_DAYS_AGO = subDays(new Date(), 3);

export const BookingRequests: React.FC = () => {
	const { setTalosAlert } = useContext(AlertContext);

	const queryClient = useQueryClient();
	const authContext = useContext(AuthContext);
	const theme = useTheme();

	const [dateRange, setDateRange] = useState<DateRange>({
		start: THREE_DAYS_AGO,
		end: new Date(),
	});

	const [pageIndex, setPageIndex] = useState<number>(1);

	const { data, isLoading } = useQuery(
		["booking-requests", dateRange, pageIndex],
		() => {
			const startDate = new Date(dateRange.start);
			const endDate = new Date(dateRange.end);

			return getBookingRequests(
				authContext,
				false,
				startDate,
				endDate,
				pageIndex
			);
		},
		{
			onError: (e: Error) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong fetching booking requests, please try again later or contact IOPS Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
		}
	);

	const bookingRequestCancelMutation = useMutation(
		(id: string) => cancelBookingRequests(authContext, id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("booking-requests");
				setTalosAlert({
					message: "Successfuly canceled Booking Request",
					severity: "success",
				});
			},
			onError: (e: Error) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong canceling booking request, please try again later or contact IOPS Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
		}
	);

	const handlePageChange = (pageIndex: number) => setPageIndex(pageIndex);

	const handleChangeDateRange = (dateRange: DateRange) => {
		setDateRange(dateRange);
		handlePageChange(1);
	};

	return (
		<Box>
			<Typography variant="h2">Booking Requests</Typography>
			<br />
			<Typography variant="body1">
				This page gives you a list of booking requests that have been sent to
				MDS.
			</Typography>
			<Typography variant="body1">
				<strong>
					Please note that we only know that these have been sent to MDS we
					don&apos;t, right now, know if MDS has accepted them.
				</strong>
			</Typography>
			<Typography variant="body1">
				Agents are advised to email MDS as usual.
			</Typography>
			<Typography variant="body1">
				<strong>
					The files generated from this process will be generate overnight and
					sent to MDS the next day.
				</strong>
			</Typography>
			<Typography variant="body1">
				<em>Note:</em> new requests can take a few minutes to appear. If not
				immediately visible try refreshing the page after a few minutes.
			</Typography>
			{authContext.hasRole(ROLES.BOOKING_REQUEST_WRITE) && (
				<Typography variant="body1">
					You can add new bookings <Link href="/new-booking-request">here</Link>
				</Typography>
			)}
			<List>
				<ListItem sx={{ padding: 0, margin: 0 }}>
					<ListItemText>
						<strong>SENT</strong> - The appointment has been sent to MDS.
					</ListItemText>
				</ListItem>
				<ListItem sx={{ padding: 0, margin: 0 }}>
					<ListItemText>
						<strong>PENDING</strong> - The request is in the queue and will be
						sent to MDS in the next batch.
					</ListItemText>
				</ListItem>
				<ListItem sx={{ padding: 0, margin: 0 }}>
					<ListItemText>
						<strong>FAILED</strong> - Something went wrong please see the
						associated error message and re-submit.
					</ListItemText>
				</ListItem>
			</List>
			<Divider sx={{ margin: theme.spacing(2, 0) }} />
			<TalosDateRangePicker
				handleSubmit={handleChangeDateRange}
				loading={isLoading}
				initialValues={{
					start: subDays(new Date(), 3).toISOString(),
					end: new Date().toISOString(),
				}}
			/>

			{isLoading || !data ? (
				<CircularProgress />
			) : (
				<>
					<BookingRequestsTable
						bookingRequestCancelMutation={bookingRequestCancelMutation}
						results={data}
					/>
					<ListPagination
						results={data}
						pageIndex={pageIndex}
						handlePageChange={handlePageChange}
					></ListPagination>
				</>
			)}
		</Box>
	);
};
