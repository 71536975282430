import { format, subDays } from "date-fns";

import {
	BookingRequest,
	BookingRequestResponse,
	ChangeReadRequest,
	ChangeReadResponse,
	IChangeReadFilter,
} from "../../models/talos";
import { IAuthContext } from "../../auth";
import { baseRequest } from "../common/base-request";
import { Page } from "../../components/pagination/pageable";
import {
	D0205FlowRequest,
	D0205FlowsResponse,
} from "../../models/talos/d0205-flows";
import {
	D0052FlowRequest,
	D0052FlowsResponse,
} from "../../models/talos/d0052-flows";
import {
	UT003FlowsResponse,
	UT003Request,
} from "../../models/talos/ut003-flows";
import { MW2FlowRequest, MW2FlowsResponse } from "../../models/talos/mw2-flows";
import { MW4FlowRequest, MW4FlowsResponse } from "../../models/talos/mw4-flows";
import { S33FlowRequest, S33FlowsResponse } from "../../models/talos/s33-flows";
import {
	F09FlowsRequest,
	F09FlowsResponse,
} from "../../models/talos/f09-flows";
import { SI2FlowsResponse } from "../../models/talos/si2-flows";
import { baseRequestOperations } from "../common/base-request-operations";
import { FVRResponse } from "../../models/talos/forced-validation-reads";
import { PAST, XWorkdaysFromDate } from "../../utilities";
import { notEmpty } from "../../utilities/predicates";

export enum API_TYPES {
	CMR = "cmr",
	READS = "reads",
	FLOWS = "flows-service/api/v1",
	EXCEPTIONS = "outbound-proxy/flow",
	SETTLEMENTS_READS = "settlements-reads/api",
}

const SEVEN_DAYS_AGO = subDays(new Date(), 7);

export const getBookingRequests = async (
	authContext: IAuthContext,
	unsentOnly: Boolean = false,
	startDate: Date = SEVEN_DAYS_AGO,
	endDate: Date = new Date(),
	pageIndex: number = 0
): Promise<Page<BookingRequestResponse>> => {
	const res = await baseRequest(
		authContext,
		"GET",
		`${API_TYPES.CMR}/booking-request`,
		undefined,
		{
			unsentOnly,
			startDate: format(startDate, "yyyy-MM-dd"),
			endDate: format(endDate, "yyyy-MM-dd"),
			pageIndex: pageIndex - 1,
		}
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return res.data;
};

export const addBookingRequest = async (
	authContext: IAuthContext,
	bookingRequest: BookingRequest
): Promise<Boolean> => {
	const res = await baseRequest(
		authContext,
		"POST",
		`${API_TYPES.CMR}/booking-request`,
		bookingRequest,
		undefined
	);

	if (res.status !== 201) {
		return false;
	}

	return true;
};

export const cancelBookingRequests = async (
	authContext: IAuthContext,
	id: string
): Promise<Boolean> => {
	const res = await baseRequest(
		authContext,
		"PATCH",
		`${API_TYPES.CMR}/booking-request/${id}/cancel`,
		undefined,
		undefined
	);

	if (res.status !== 202) {
		throw new Error(`Error calling API ${res}`);
	}

	return true;
};

export const addChangeReadRequest = async (
	authContext: IAuthContext,
	request: ChangeReadRequest
): Promise<Boolean> => {
	const res = await baseRequest(
		authContext,
		"POST",
		`${API_TYPES.READS}/change-read`,
		request,
		undefined
	);

	if (res.status !== 201) {
		return false;
	}

	return true;
};

export const getChangeReadRequests = async (
	authContext: IAuthContext,
	filter: IChangeReadFilter,
	pageIndex: number = 0
): Promise<Page<ChangeReadResponse>> => {
	const { mpan, unsentOnly, start, end } = filter;

	const res = await baseRequest(
		authContext,
		"GET",
		`${API_TYPES.READS}/change-read`,
		undefined,
		{
			...(mpan && { mpan }),
			unsentOnly,
			startDate: format(start, "yyyy-MM-dd"),
			endDate: format(end, "yyyy-MM-dd"),
			pageIndex: pageIndex - 1,
		}
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return res.data;
};

export const getChangeReadRequestById = async (
	id: string,
	authContext: IAuthContext
): Promise<ChangeReadResponse> => {
	const res = await baseRequest(
		authContext,
		"GET",
		`${API_TYPES.READS}/change-read/${id}`,
		undefined,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return res.data;
};

export const cancelChangeReadRequests = async (
	authContext: IAuthContext,
	id: string
): Promise<Boolean> => {
	const res = await baseRequest(
		authContext,
		"PATCH",
		`${API_TYPES.READS}/change-read/${id}/cancel`,
		undefined,
		undefined
	);

	if (res.status !== 202) {
		throw new Error(`Error calling API ${res}`);
	}

	return true;
};

export const getD0205Flows = async (
	authContext: IAuthContext
): Promise<D0205FlowsResponse[]> => {
	const res = await baseRequest(
		authContext,
		"GET",
		`${API_TYPES.FLOWS}/d0205/`,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return res.data;
};

export const addD0205FlowRequest = async (
	authContext: IAuthContext,
	request: D0205FlowRequest
): Promise<Boolean> => {
	const res = await baseRequest(
		authContext,
		"POST",
		`${API_TYPES.FLOWS}/d0205/`,
		request,
		undefined
	);

	if (res.status !== 201) {
		return false;
	}

	return true;
};

export const getD0052Flows = async (
	authContext: IAuthContext
): Promise<D0052FlowsResponse[]> => {
	const res = await baseRequest(
		authContext,
		"GET",
		`${API_TYPES.FLOWS}/d0052/`,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return res.data;
};

export const addD0052FlowRequest = async (
	authContext: IAuthContext,
	request: D0052FlowRequest
): Promise<Boolean> => {
	const res = await baseRequest(
		authContext,
		"POST",
		`${API_TYPES.FLOWS}/d0052/`,
		request,
		undefined
	);

	if (res.status !== 201) {
		return false;
	}

	return true;
};

export const getUT003Flows = async (
	authContext: IAuthContext
): Promise<UT003FlowsResponse[]> => {
	const res = await baseRequest(
		authContext,
		"GET",
		`${API_TYPES.FLOWS}/ut003/`,
		undefined
	);

	if (res.status !== 200) {
		throw new Error(`Error calling API ${res}`);
	}

	return res.data;
};

export const addUT003FlowsRequest = async (
	authContext: IAuthContext,
	request: UT003Request
): Promise<Boolean> => {
	const res = await baseRequest(
		authContext,
		"POST",
		`${API_TYPES.FLOWS}/ut003/`,
		request,
		undefined
	);

	if (res.status !== 201) {
		return false;
	}

	return true;
};

export const addMW2FlowRequest = async (
	authContext: IAuthContext,
	request: MW2FlowRequest
): Promise<Boolean> => {
	const res = await baseRequestOperations(
		authContext,
		"POST",
		`${API_TYPES.EXCEPTIONS}/mw2`,
		request,
		undefined
	);

	if (res.status !== 201) {
		return false;
	}

	return true;
};

export const addMW4FlowRequest = async (
	authContext: IAuthContext,
	request: MW4FlowRequest
): Promise<Boolean> => {
	const res = await baseRequestOperations(
		authContext,
		"POST",
		`${API_TYPES.EXCEPTIONS}/mw4`,
		request,
		undefined
	);

	if (res.status !== 201) {
		return false;
	}

	return true;
};

export const addS33FlowRequest = async (
	authContext: IAuthContext,
	request: S33FlowRequest
): Promise<Boolean> => {
	const res = await baseRequestOperations(
		authContext,
		"POST",
		`${API_TYPES.EXCEPTIONS}/s33`,
		request,
		undefined
	);

	if (res.status !== 201) {
		return false;
	}

	return true;
};

const getFlows =
	<TFlowResponse, TGetFlowsOptions = unknown>(
		flow: string,
		apiType: API_TYPES = API_TYPES.FLOWS,
		transform: (response: any) => TFlowResponse = (response) => response.data
	) =>
	async (
		authContext: IAuthContext,
		getFlowsOptions?: TGetFlowsOptions
	): Promise<TFlowResponse> => {
		const res = await baseRequestOperations(
			authContext,
			"GET",
			`${apiType}/${flow}`,
			undefined,
			getFlowsOptions
		);

		if (res.status !== 200) {
			throw new Error(`Error calling API ${res}`);
		}

		return transform(res);
	};

export const addFlowRequest =
	<TFlowRequest>(
		requestPathFragment: string,
		apiType: API_TYPES = API_TYPES.FLOWS
	) =>
	async (
		authContext: IAuthContext,
		request: TFlowRequest
	): Promise<Boolean> => {
		const res = await baseRequestOperations(
			authContext,
			"POST",
			`${apiType}/${requestPathFragment}`,
			request,
			undefined
		);

		return res.status === 201;
	};

/**
 * Get F09 flows and enhance with Paging structure, this is because backend
 * currently doesn't support paging but the front end does so needs data in
 * `Page<T>` form. This will allow for quick support of paging in future if
 * backend is * modified to support it.
 */
export const getMW2Flows = getFlows<Page<MW2FlowsResponse>>(
	"mw2",
	API_TYPES.EXCEPTIONS
);

export const getMW4Flows = getFlows<Page<MW4FlowsResponse>>(
	"mw4",
	API_TYPES.EXCEPTIONS
);

export const getS33Flows = getFlows<Page<S33FlowsResponse>>(
	"s33",
	API_TYPES.EXCEPTIONS
);

export const getF09Flows = getFlows<Page<F09FlowsResponse>>(
	"f09",
	API_TYPES.EXCEPTIONS
);

export const addF09FlowRequest = addFlowRequest<F09FlowsRequest>(
	"f09",
	API_TYPES.EXCEPTIONS
);

export const getSI2Flows = getFlows<Page<SI2FlowsResponse>>(
	"si2",
	API_TYPES.EXCEPTIONS
);

interface FVRFilterOptions {
	startDate?: Date;
	endDate?: Date;
	page?: number;
	pageSize?: number;
	mpan?: string;
}

export const getFVRRequests = getFlows<Page<FVRResponse>, FVRFilterOptions>(
	"fvr",
	API_TYPES.SETTLEMENTS_READS
);

export const getRecentFVRRequests = async (
	authContext: IAuthContext,
	mpan: string,
	daysToRetrieveFromCurrentDate: number,
	pageSize: number = 1
) => {
	const endDate = new Date();
	const startDate = XWorkdaysFromDate(
		endDate,
		daysToRetrieveFromCurrentDate,
		PAST
	);

	const formatDateForTransmission = (date: Date) => format(date, "yyyy-MM-dd");

	const CREATION_DATE_QUERY_PARAM_NAME = "creationDate";
	const FILTER_QUERY_PARAM_NAME = "filterBy";
	const searchParams = Object.fromEntries(
		[
			startDate
				? [
						FILTER_QUERY_PARAM_NAME,
						`${CREATION_DATE_QUERY_PARAM_NAME}::gt::${formatDateForTransmission(
							startDate
						)}`,
				  ]
				: undefined,
			endDate
				? [
						FILTER_QUERY_PARAM_NAME,
						`${CREATION_DATE_QUERY_PARAM_NAME}::lte::${formatDateForTransmission(
							endDate
						)}`,
				  ]
				: undefined,
			mpan ? [FILTER_QUERY_PARAM_NAME, `mpan::eq::${mpan}`] : undefined,
			pageSize ? ["pageSize", pageSize] : undefined,
		].filter(notEmpty)
	);

	return await getFVRRequests(authContext, searchParams);
};
