import React, { useContext, useState } from "react";

import { UseMutationResult } from "react-query";
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Divider,
	Menu,
	MenuItem,
	Modal,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	Typography,
} from "@mui/material";

import { BookingRequest, BookingRequestResponse } from "../../models/talos";
import { theme } from "../../styles/theme";
import { formatJavaStyleDateString, ROLES } from "../../utilities";
import { BookingRequestsFullVerticalTable } from "./booking-requests-full-vertical-table";
import { AuthContext } from "../../auth";
import { Page } from "../pagination/pageable";

interface IProps {
	results: Page<BookingRequestResponse>;
	bookingRequestCancelMutation: UseMutationResult<Boolean, Error, string>;
}

export const BookingRequestsTable: React.FC<IProps> = (props: IProps) => {
	const [showFullDetails, setShowFullDetails] = useState(false);
	const [showWarning, setShowWarning] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selected, setSelected] = useState<BookingRequestResponse | undefined>(
		undefined
	);

	const { hasRole } = useContext(AuthContext);

	const handleActionsClick = (
		event: React.MouseEvent<HTMLButtonElement>,
		selected: BookingRequestResponse
	) => {
		setAnchorEl(event.currentTarget);
		setSelected(selected);
	};

	const handleClose = () => {
		setAnchorEl(null);
		setSelected(undefined);
	};

	const handleCancel = () => {
		if (selected) {
			props.bookingRequestCancelMutation.mutate(selected.id);
			handleClose();
			setShowWarning(false);
		}
	};

	const getGasAndOrElec = (
		gas: JSX.Element | undefined,
		elec: JSX.Element | undefined
	) => {
		if (elec && gas) {
			return (
				<>
					{elec}
					<Divider />
					{gas}
				</>
			);
		} else if (elec && !gas) {
			return <>{elec}</>;
		} else if (!elec && gas) {
			return <>{gas}</>;
		}
		return <></>;
	};

	const getMPXN = (booking: BookingRequest): JSX.Element => {
		const mpan = booking.electricityMeterData ? (
			<Typography variant="body1">
				Elec - {booking.electricityMeterData.mpan}
			</Typography>
		) : undefined;
		const mprn = booking.gasMeterData ? (
			<Typography variant="body1">Gas - {booking.gasMeterData.mprn}</Typography>
		) : undefined;

		return getGasAndOrElec(mprn, mpan);
	};

	const getActions = (result: BookingRequestResponse) => {
		const showCancelButton =
			result.status === "PENDING" && hasRole(ROLES.BOOKING_REQUEST_CANCEL);
		return (
			<>
				<Button
					id="basic-button"
					onClick={(e) => handleActionsClick(e, result)}
				>
					Actions
				</Button>
				<Menu
					id="basic-menu"
					anchorEl={anchorEl}
					open={Boolean(anchorEl) && !!selected && result.id === selected.id}
					onClose={handleClose}
					MenuListProps={{
						"aria-labelledby": "basic-button",
					}}
					PaperProps={{
						sx: {
							filter: "drop-shadow(0px 1px 5px rgba(0,0,0,0.06))",
							boxShadow: "1px 1px  #4c4c4c",
						},
					}}
				>
					{showCancelButton ? (
						<MenuItem
							onClick={() => {
								setSelected(result);
								setShowWarning(true);
							}}
						>
							Cancel
						</MenuItem>
					) : null}
					<MenuItem
						onClick={() => {
							setSelected(result);
							setShowFullDetails(true);
						}}
					>
						Full Details
					</MenuItem>
				</Menu>
			</>
		);
	};

	return (
		<>
			<TableContainer
				component={Paper}
				sx={{ marginTop: theme.spacing(2), marginBottom: theme.spacing(3) }}
			>
				<Table size="small" data-cy="booking-requests-table">
					<TableHead>
						<TableRow sx={{ background: "#efefef" }}>
							<TableCell>Status</TableCell>
							<TableCell>Request Received</TableCell>
							<TableCell>Agent</TableCell>
							<TableCell>File Generated</TableCell>
							<TableCell>Job Code</TableCell>
							<TableCell>Customer Name</TableCell>
							<TableCell>MPXN</TableCell>
							<TableCell>Actions</TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.results.items
							.sort(
								(a, b) =>
									new Date(
										formatJavaStyleDateString(b.dateRequestReceived, true)
									).getTime() -
									new Date(
										formatJavaStyleDateString(a.dateRequestReceived, true)
									).getTime()
							)
							.map((result) => (
								<TableRow key={result.id}>
									<TableCell>
										{result.status === "SUCCESS" ? "SENT" : result.status}
									</TableCell>
									<TableCell>
										{formatJavaStyleDateString(
											result.dateRequestReceived,
											true
										)}
									</TableCell>
									<TableCell>{result.bookingRequest.agentName}</TableCell>
									<TableCell>
										{formatJavaStyleDateString(result.dateRequestGenerated)}
									</TableCell>
									<TableCell>{result.bookingRequest.jobCode}</TableCell>
									<TableCell>{result.bookingRequest.customerName}</TableCell>
									<TableCell>{getMPXN(result.bookingRequest)}</TableCell>
									<TableCell>{getActions(result)}</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
			<Dialog open={showWarning}>
				<DialogTitle>Are you sure?</DialogTitle>
				<DialogContent>Cancelling a request is not reversible</DialogContent>
				<DialogActions>
					<Button onClick={() => setShowWarning(false)}>No</Button>
					<Button onClick={() => handleCancel()}>Yes</Button>
				</DialogActions>
			</Dialog>
			<Modal
				open={showFullDetails}
				onClose={() => {
					setShowFullDetails(false);
					setSelected(undefined);
				}}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box
					sx={{
						position: "absolute" as "absolute",
						top: "50%",
						left: "50%",
						transform: "translate(-50%, -50%)",
						width: "50%",
						bgcolor: "background.paper",
						border: "2px solid #000",
						boxShadow: 24,
						p: 4,
						height: "50%",
						overflow: "scroll",
					}}
				>
					<BookingRequestsFullVerticalTable selected={selected} />
				</Box>
			</Modal>
		</>
	);
};
