import { AxiosResponse } from "axios";

import { IAuthContext } from "../../auth";
import {
	IUpdateLegacyDataItem,
	IUpdateIHDStatus,
	IUpdateConsentGranularity,
	ISupplyDisconnection,
	IInstallRemoveMeter,
} from "../../models/metering";
import { baseRequestOperations } from "../common/base-request-operations";
import { ISupplyDeactivation } from "../../models/metering/supply-deactivation";
import { IUpdateEnergisationStatus } from "../../models/metering/update-energisation-status";

export const updateLegacyDataItem = async (
	authContext: IAuthContext,
	legacyDataItem: IUpdateLegacyDataItem
): Promise<AxiosResponse> => {
	const res = await baseRequestOperations(
		authContext,
		"POST",
		"outbound-proxy/supply-point/legacy-data",
		legacyDataItem,
		undefined
	);

	return res;
};

export const updateIHDStatus = async (
	authContext: IAuthContext,
	ihdStatus: IUpdateIHDStatus
): Promise<AxiosResponse> => {
	const res = await baseRequestOperations(
		authContext,
		"POST",
		"outbound-proxy/supply-point/ihd-status",
		ihdStatus,
		undefined
	);

	return res;
};

export const updateConsentGranularity = async (
	authContext: IAuthContext,
	consent: IUpdateConsentGranularity
): Promise<AxiosResponse> => {
	const res = await baseRequestOperations(
		authContext,
		"POST",
		"outbound-proxy/supply-point/consent",
		consent,
		undefined
	);

	return res;
};

export const requestSupplyDeactivation = async (
	authContext: IAuthContext,
	supplyDeactivation: ISupplyDeactivation
): Promise<AxiosResponse> => {
	const res = await baseRequestOperations(
		authContext,
		"POST",
		"outbound-proxy/supply-point/deactivation",
		supplyDeactivation,
		undefined
	);

	return res;
};

export const requestSupplyDisconnection = async (
	authContext: IAuthContext,
	supplyDisconnection: ISupplyDisconnection
): Promise<AxiosResponse> => {
	const res = await baseRequestOperations(
		authContext,
		"POST",
		"outbound-proxy/supply-point/disconnection",
		supplyDisconnection,
		undefined
	);

	return res;
};

export const updateEnergisationStatus = async (
	authContext: IAuthContext,
	energisationStatus: IUpdateEnergisationStatus
): Promise<AxiosResponse> => {
	const res = await baseRequestOperations(
		authContext,
		"POST",
		"outbound-proxy/supply-point/energisation",
		energisationStatus,
		undefined
	);

	return res;
};

export const requestInstallRemoveMeter = async (
	authContext: IAuthContext,
	installRemoveMeter: IInstallRemoveMeter
): Promise<AxiosResponse> => {
	const res = await baseRequestOperations(
		authContext,
		"POST",
		"outbound-proxy/supply-point/meter-installation-removal-appointment",
		installRemoveMeter,
		undefined
	);

	return res;
};
