export interface DropDownItem {
	display: string;
	value: string;
}

export const WORKLISTS_DROPDOWN: DropDownItem[] = [
	{ display: "Failed Reads", value: "FAILED_READS" },
	{ display: "Large EAC/AA", value: "LARGE_EAC_AA" },
	{ display: "Gap", value: "GAP" },
	{ display: "RF", value: "RF" },
	{ display: "Cases", value: "CASES" },
];

export const ROOT_CAUSES_DROPDOWN: DropDownItem[] = [
	{ display: "Incorrect Read Validated", value: "INCORRECT_READ_VALIDATED" },
	{ display: "Incorrect Routine Read", value: "INCORRECT_ROUTINE_READ" },
];

export const READ_TYPES = [
	"A",
	"B",
	"C",
	"D",
	"F",
	"I",
	"M",
	"O",
	"P",
	"Q",
	"R",
	"S",
	"T",
	"W",
	"Z",
];

const CHANGE_READ_STATUS = ["PENDING", "SENT", "FAILED", "CANCELLED"];

export type ReadType = (typeof READ_TYPES)[number];
export type ChangeReadStatus = (typeof CHANGE_READ_STATUS)[number];

const ROOT_CAUSES = ROOT_CAUSES_DROPDOWN.map((x) => x.value);
export type RootCause = (typeof ROOT_CAUSES)[number];

const WORKLISTS = WORKLISTS_DROPDOWN.map((x) => x.value);
export type WorkList = (typeof WORKLISTS)[number];

export interface ChangeReadRequest {
	worklist: WorkList;
	mpan: string;
	msn: string;
	rootCause: RootCause;
	readDate: string;
	readType: ReadType;
	firstRegisterId: string;
	firstRegisterIncorrectRead: number;
	firstRegisterCorrectRead: number;
	secondRegisterId?: string;
	secondRegisterIncorrectRead?: number;
	secondRegisterCorrectRead?: number;
}

export interface IChangeReadFormValues {
	worklist: WorkList | "";
	mpan: string;
	msn: string;
	rootCause: RootCause | "";
	readDate: string;
	readType: ReadType | "";
	firstRegisterId: string;
	firstRegisterIncorrectRead: number | "";
	firstRegisterCorrectRead: number | "";
	subRegisters: "Y" | "N";
	secondRegisterId?: string;
	secondRegisterIncorrectRead: number | "";
	secondRegisterCorrectRead: number | "";
}

export interface ChangeReadResponse {
	id: string;
	changeRead: ChangeReadRequest;
	dateTimeRequestReceived: string;
	dateTimeFileGenerated?: string;
	status: ChangeReadStatus;
	agentName: string;
	agentEmail: string;
	errors?: string[];
}

export interface IChangeReadFilter {
	mpan: string | undefined;
	start: Date;
	end: Date;
	unsentOnly: boolean;
}
