import React, { useContext, useState } from "react";

import {
	Box,
	CircularProgress,
	Divider,
	Link,
	Typography,
	useTheme,
} from "@mui/material";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { subDays } from "date-fns";

import { ChangeReadsFilter, ChangeReadsTable } from "../../components";
import { IChangeReadFilter } from "../../models/talos";
import { AuthContext } from "../../auth";
import { AlertContext, handleError, ROLES } from "../../utilities";
import {
	cancelChangeReadRequests,
	getChangeReadRequests,
} from "../../api/talos";
import { ListPagination } from "../../components/pagination/pagination";

const THREE_DAYS_AGO = subDays(new Date(), 3);
const TODAY = new Date();

export const ChangeReads: React.FC = () => {
	const [filter, setFilter] = useState<IChangeReadFilter>({
		mpan: undefined,
		start: THREE_DAYS_AGO,
		end: TODAY,
		unsentOnly: false,
	});

	const theme = useTheme();
	const authContext = useContext(AuthContext);
	const { setTalosAlert } = useContext(AlertContext);
	const queryClient = useQueryClient();

	const [pageIndex, setPageIndex] = useState<number>(1);

	const { data, isLoading } = useQuery(
		["change-reads", authContext, filter, pageIndex],
		() => getChangeReadRequests(authContext, filter, pageIndex),
		{
			onError: (e: Error) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong fetching change reads, please try again later or contact IOPS Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
		}
	);

	const changeReadsCancelMutation = useMutation(
		(id: string) => cancelChangeReadRequests(authContext, id),
		{
			onSuccess: () => {
				queryClient.invalidateQueries("change-reads");
				setTalosAlert({
					message: "Successfuly canceled Change Read Request",
					severity: "success",
				});
			},
			onError: (e: Error) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong canceling change read, please try again later or contact IOPS Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
		}
	);

	const handleChangeFilter = (filter: IChangeReadFilter) => setFilter(filter);

	const handleCancel = (id: string) => {
		changeReadsCancelMutation.mutate(id);
	};

	const handlePageChange = (pageIndex: number) => setPageIndex(pageIndex);

	return (
		<Box>
			<Typography variant="h1">Change Read Requests</Typography>
			<br />
			{authContext.hasRole(ROLES.CHANGE_READ_WRITE) && (
				<Typography variant="body1">
					To add another change read{" "}
					<Link href="/add-change-read-request">click here</Link>
				</Typography>
			)}
			<Divider sx={{ margin: theme.spacing(3, 0) }} />
			<ChangeReadsFilter
				handleSubmit={handleChangeFilter}
				loading={isLoading}
				initialValues={filter}
				handlePageChange={handlePageChange}
			/>
			{isLoading || !data ? (
				<CircularProgress />
			) : (
				<>
					<ChangeReadsTable handleCancel={handleCancel} results={data} />
					<ListPagination
						results={data}
						pageIndex={pageIndex}
						handlePageChange={handlePageChange}
					></ListPagination>
				</>
			)}
		</Box>
	);
};
