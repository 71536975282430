import { FC, ReactNode, useContext, useState } from "react";

import {
	Box,
	CircularProgress,
	Divider,
	Typography,
	useTheme,
} from "@mui/material";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";

import { AlertContext, handleError, ROLES } from "../utilities";
import { AuthContext, IAuthContext } from "../auth";
import { ListPagination } from "../components/pagination/pagination";
import { Page } from "../components/pagination/pageable";

interface BaseProps<TResponse, TRequestsTableRowData, TGetRequestsOptions> {
	children?: ReactNode;
	title: string;
	requestsName: string;
	requestsKey: string;
	getRequests: (
		auth: IAuthContext,
		getRequestsOptions: TGetRequestsOptions
	) => Promise<TResponse>;
	RequestsTable: FC<{ rowData: TRequestsTableRowData[] }>;
	additionLink?: string;
	addendum?: any;
	renderTable: (results: TResponse) => ReactNode;
	addRequestsRole?: (typeof ROLES)[keyof typeof ROLES];
	getRequestsOptions: Record<any, any>;
}

/**
 * Utility type to enrich request-options type with page index required to support paging.
 */
type Paging<TRequestOptions> = TRequestOptions & { pageIndex: number };

/**
 * Public properties for these types do not include the `renderTable` function
 * as this is only used by the base component and shouldn't be accessible external
 * this file.
 */
type PublicProps<TResponse, TRequestsTableRowData, TGetRequestsOptions> = Omit<
	BaseProps<TResponse, TRequestsTableRowData, TGetRequestsOptions>,
	"renderTable"
>;

const BaseRequestsPage = <
	TResponse,
	TRequestsTableRowData,
	TGetRequestsOptions
>({
	children,
	title,
	requestsName,
	requestsKey,
	getRequests,
	additionLink,
	addendum,
	renderTable,
	addRequestsRole = ROLES.FLOWS_ADMIN,
	getRequestsOptions,
}: BaseProps<TResponse, TRequestsTableRowData, TGetRequestsOptions>) => {
	const theme = useTheme();
	const authContext = useContext(AuthContext);

	const { setTalosAlert } = useContext(AlertContext);

	const { data, isLoading } = useQuery(
		[requestsKey, authContext],
		async () => {
			try {
				return await getRequests(authContext, getRequestsOptions);
			} catch (e) {
				console.log("Failed to fetch data", e);
				throw e;
			}
		},
		{
			onError: (e: Error) => {
				const errorId = handleError(e);
				setTalosAlert({
					message: `Something went wrong while fetching ${requestsName} requests, please try again later or contact IOps Support. Ticket ID: ${errorId}`,
					severity: "error",
				});
			},
		}
	);

	return (
		<Box data-cy={`${requestsKey}-panel`}>
			<Typography
				data-cy={`${requestsKey}-panel-header`}
				variant="h1"
			>{`${title} Requests`}</Typography>
			<br />
			{children}
			{authContext.hasRole(addRequestsRole) && additionLink && (
				<Typography variant="body1" data-cy="add-request-text">
					{`To add another ${requestsName} request `}
					<Link to={additionLink} data-cy="add-request-link">
						click here
					</Link>
				</Typography>
			)}
			{addendum}
			<Divider sx={{ margin: theme.spacing(3, 0) }} />

			{isLoading || !data ? <CircularProgress /> : <>{renderTable(data)}</>}
		</Box>
	);
};

export const PagedRequestsPage = <
	TResponse,
	TPagedResponse extends Page<TResponse> = Page<TResponse>,
	TGetRequestsOptions extends Record<any, any> = {}
>(
	props: PublicProps<TPagedResponse, TResponse, Paging<TGetRequestsOptions>>
) => {
	const [pageIndex, setPageIndex] = useState<number>(1);
	return (
		<BaseRequestsPage<TPagedResponse, TResponse, Paging<TGetRequestsOptions>>
			{...props}
			getRequestsOptions={{
				...props.getRequestsOptions,
				pageIndex: pageIndex - 1,
			}}
			renderTable={(results) => (
				<>
					<props.RequestsTable rowData={results.items} />
					<ListPagination
						results={results}
						pageIndex={pageIndex}
						handlePageChange={setPageIndex}
					></ListPagination>
				</>
			)}
		></BaseRequestsPage>
	);
};
