import * as Yup from "yup";

import { dateIsNotValid, usefulRegex } from "../../utilities";

export const longSNACValidationMessage =
	"This entry is more than 5 digits long. This seems like it could be an error, are you sure you want to proceed?";

export const formValidationSchema = Yup.object().shape(
	{
		mpan: Yup.string()
			.matches(usefulRegex.MPAN, "MPAN must be a 13 digit integer")
			.required("MPAN is required"),

		supplierNotificationType: Yup.string().required(
			"Supplier Notification Type is required"
		),

		supplierNotificationEffectiveFromDate: Yup.string()
			.required("Supplier Notification EFD is required")
			.test(
				"sn-efd-is-not-valid",
				"Supplier Notification EFD should be a valid date",
				dateIsNotValid
			),

		supplierNotificationExpiryDate: Yup.string()
			.nullable()
			.when("supplierNotificationExpiryDate", {
				is: (value: string) => value && value !== "" && value !== "dd/MM/yyyy",
				then: (schema) =>
					schema
						.required("Supplier Notification Expiry Date is required")
						.test(
							"sn-ed-is-not-valid",
							"Supplier Notification Expiry Date should be a valid date",
							dateIsNotValid
						),
			}),

		meterIdSerialNumber: Yup.string().when("supplierNotificationType", {
			is: (value: string) => value === "SNRD" || value === "SNRE",
			then: (schema) =>
				schema
					.matches(
						usefulRegex.METER_ID,
						"Meter Id Serial Number must be between 3 and 10 characters"
					)
					.required("Meter Id Serial Number is required"),
		}),

		additionalInformation: Yup.string()
			.nullable()
			.transform((curr, orig) => (orig === "" ? null : curr))
			.when("additionalInformation", {
				is: (value: string) => value && value !== "",
				then: (schema) =>
					schema
						.required("Additional Information is required")
						.test(
							"len",
							"Additional Information must be less then 200 characters",
							(value: string) => value.length <= 200
						),
			}),

		supplierNominatedAnnualConsumption: Yup.string().when(
			"supplierNotificationType",
			{
				is: (value: string) => value === "SNAC",
				then: (schema) =>
					schema
						.matches(
							usefulRegex.SNAC,
							"Supplier Nominated Annual Consumption must be up to 13 digit integer"
						)
						.required("Supplier Nominated Annual Consumption is required"),
			}
		),
	},
	[
		["additionalInformation", "additionalInformation"],
		["supplierNotificationExpiryDate", "supplierNotificationExpiryDate"],
	]
);
