import React from "react";

import { Box } from "@mui/material";
import { Form, Formik } from "formik";

import { TalosTextBox, TalosDatePicker, TalosButton } from "../forms";
import { IChangeReadFilter } from "../../models/talos";
import { validationSchema } from "./change-reads-filter-validation-schema";

interface IProps {
	handleSubmit: (filter: IChangeReadFilter) => void;
	loading: boolean;
	initialValues: IChangeReadFilter;
	handlePageChange: (pageIndex: number) => void;
}

export const ChangeReadsFilter: React.FC<IProps> = (props: IProps) => {
	return (
		<Formik
			initialValues={props.initialValues}
			onSubmit={props.handleSubmit}
			validationSchema={validationSchema}
		>
			{(form) => (
				<Form>
					<Box className="form-wrapper-horizontal">
						<Box className="form-column-narrow">
							<TalosTextBox fieldName="mpan" label="MPAN" form={form} />
						</Box>
						<Box className="form-column-narrow">
							<TalosDatePicker
								fieldName="start"
								label="Start Date"
								form={form}
							/>
						</Box>
						<Box className="form-column-narrow">
							<TalosDatePicker fieldName="end" label="End Date" form={form} />
						</Box>
						<Box
							className="form-column-narrow"
							onClick={() => props.handlePageChange(1)}
						>
							<TalosButton
								fieldName="search_button"
								buttonText="Search"
								form={form}
								loading={props.loading}
								enableIfNotDirty={true}
							/>
						</Box>
					</Box>
				</Form>
			)}
		</Formik>
	);
};
