import { Alert, Snackbar, Typography } from "@mui/material";

export const TalosRolesSnackbar: React.FC = () => {
	return (
		<Snackbar open anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
			<Alert
				severity="error"
				variant="filled"
				sx={{
					alignItems: "center",
					fontSize: "19px",
					height: "100%",
					margin: "20px 0",
					width: "100%",
				}}
			>
				<Typography variant="h3">
					<strong>
						This user has no permissions! Please see your system administrator!
					</strong>
				</Typography>
			</Alert>
		</Snackbar>
	);
};
