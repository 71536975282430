import { AxiosResponse } from "axios";

import { IAuthContext } from "../../auth";
import { IMOP04v10 } from "../../models/outbound";
import { baseRequestOperations } from "../common/base-request-operations";

export const submitMOP04 = async (
	authContext: IAuthContext,
	mop04v10: IMOP04v10
): Promise<AxiosResponse> => {
	const res = await baseRequestOperations(
		authContext,
		"POST",
		"outbound-proxy/meter/mop04",
		mop04v10,
		undefined
	);

	return res;
};
